import axios from '@/services/axios'
export default function useCustomerRepositories() {
  const resource = `orders/customers`
  const getOrdersOfCustomer = async (email: string) => {
    try {
      const params = {
        email: email,
      }
      const res = await axios.get(`${resource}/detail`, { params })
      console.log(1111, res)
    } catch (error) {
      console.log(error)
      return null
    }
  }
  const sendEmailToCustomers = async (params: any) => {
    try {
      const res = await axios.post(`${resource}/create-bulk-email-job`, params)
      return res
    } catch (error) {
      console.log(error)
      return null
    }
  }
  return { getOrdersOfCustomer, sendEmailToCustomers }
}
