
import { computed, defineComponent, ref } from 'vue'
import useUploadRepositories from '@/repositories/useUploadRepositories'
import useCustomerRepositories from '@/repositories/useCustomerRepositories'
import { Link, Delete, UploadFilled } from '@element-plus/icons-vue'
import { imageUrl } from '@/utils/image'
export default defineComponent({
  components: { UploadFilled, Link, Delete },
  setup(props, { emit }) {
    const dialogVisible = ref<boolean>(false)
    const fileList = ref<any>([])
    const subject = ref<string>('')
    const platform = ref<string>('')
    const sender = ref<string>('')
    const params = ref<any>({})
    const loading = ref<boolean>(false)
    const showErrorMessage = ref<boolean>(false)

    const listEmail = computed(() => {
      let listEmailTemp = [{}]
      if (platform.value === 'latte') {
        listEmailTemp = [
          {
            label: 'support@moticano.com',
            value: 'support@moticano.com',
          },
        ]
      } else {
        listEmailTemp = [
          {
            label: 'support@moticano.com',
            value: 'support@moticano.com',
          },
        ]
      }
      return listEmailTemp
    })
    const close = () => {
      dialogVisible.value = false
    }
    const open = (paramsObject: any) => {
      params.value = paramsObject
      showErrorMessage.value = false
      fileList.value = []
      dialogVisible.value = true
    }

    const { onUploadTemplate } = useUploadRepositories()
    const { sendEmailToCustomers } = useCustomerRepositories()
    const uploadFile = async ($event: Event) => {
      loading.value = true
      const target = $event.target as HTMLInputElement
      if (target && target.files) {
        try {
          const res = await onUploadTemplate(target.files[0])
          fileList.value.push(res)
        } catch (err: any) {
          console.log(err.message)
        }
      }
      loading.value = false
    }

    const createTemplateLink = (path: string) => {
      return `https://admin-cdn.shopbase.com/${path}`
    }

    const sendEmail = async () => {
      if (
        !fileList.value[0] ||
        !subject.value.trim() ||
        !sender.value ||
        !platform.value
      ) {
        showErrorMessage.value = true
        return
      }
      showErrorMessage.value = false
      try {
        const res = await sendEmailToCustomers({
          template: fileList.value[0].path,
          subject: subject.value,
          platform: platform.value,
          sender: sender.value,
          query: { ...params.value, isSubscribed: true },
        })
        emit('success')
        close()
        console.log(res)
      } catch (err: any) {
        emit('fail')
        console.log(err.message)
      }
    }

    const deleteTemplate = () => {
      fileList.value = []
    }

    return {
      dialogVisible,
      close,
      open,
      imageUrl,
      loading,
      fileList,
      subject,
      listEmail,
      uploadFile,
      sender,
      platform,
      createTemplateLink,
      sendEmail,
      deleteTemplate,
      showErrorMessage,
    }
  },
})
