import { reactive } from 'vue'
import { useFetchData } from '@/composables'
import { useResource } from '@/services'
import axios from '@/services/axios'
import { AxiosResponse } from 'axios'

export default function useShopRepositories() {
  // const resource = `http://localhost:3001/api/shops`
  const resource = `shops`

  const { get } = useResource(resource)

  const state = reactive({})

  const getAllStores = async (search?: string) => {
    try {
      const { data, status } = await get(`list?search=${search?.trim()}`)
      if (status === 200 || status === 201) {
        return data
      }
      return []
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const search = async (params: any) => {
    try {
      const { documents, fetchData } = useFetchData(resource, params)
      await fetchData()
      return documents.value
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const mapUser = async (mappedUser: any): Promise<AxiosResponse<any, any>> => {
    return await axios.put(`mapped-user`, mappedUser)
  }

  const getStoreDomain = async (id: string) => {
    return await axios.get(`${resource}/${id}/host`)
  }

  return { search, getAllStores, mapUser, state, getStoreDomain }
}
