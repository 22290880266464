
import { computed, reactive, watch, ref, defineComponent, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData } from '@/composables'
import { imageUrl } from '@/utils/image'
import { formatTime } from '@/utils/dateTime'
import Pagination from '@/components/common/Pagination.vue'
import CustomerDetailDialog from '@/components/common/CustomerDetailDialog.vue'
import { formatterDollars } from '@/utils/formatter'
import EmailDialog from './components/EmailDialog.vue'
import { ElMessage } from 'element-plus'
import useShopRepositories from '@/repositories/useShopRepositories'
export default defineComponent({
  components: {
    sectionLayoutContent,
    Pagination,
    CustomerDetailDialog,
    EmailDialog,
  },
  setup() {
    const breadcrumbs = ['Customers', '']
    const title = 'Customers'
    const baseResource = 'orders/customers/search'

    const content = computed(() => {
      return { breadcrumbs, title }
    })
    const countries = [
      {
        id: 'US',
        name: 'United States',
      },
      {
        id: 'CA',
        name: 'Canada',
      },
    ]

    const stores = ref(null as any[] | null)

    const params = reactive({
      country: null,
      store: null,
      productPurchase: null,
      orderQuantity: null,
      amountPaid: null,
      search: null,
      page: 1,
      limit: 10,
    })

    const { documents, fetchData, isLoading, total } = useFetchData(
      baseResource,
      params
    )

    const { getAllStores } = useShopRepositories()

    onMounted(async () => {
      await fetchData()
      await getAllStoresSellers()
    })

    watch(
      () => [
        params.country,
        params.store,
        params.amountPaid,
        params.orderQuantity,
        params.page,
        params.limit,
      ],
      fetchData
    )

    const columns = [
      {
        key: '_id',
        title: 'Full Name',
        dataIndex: 'fullName',
        minWidth: '10',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        minWidth: '15',
      },
      {
        title: 'Address',
        dataIndex: 'address',
        minWidth: '20',
      },
      {
        title: 'City',
        dataIndex: 'city',
        minWidth: '10',
      },
      {
        title: 'Province',
        dataIndex: 'province',
        minWidth: '10',
      },
      {
        title: 'Country',
        dataIndex: 'country',
        minWidth: '10',
      },
      {
        title: 'Total order',
        dataIndex: 'customerLevel',
        minWidth: '8',
      },
      {
        title: 'Total paid',
        dataIndex: 'customerValue',
        minWidth: '8',
      },
      {
        title: 'Seller',
        dataIndex: 'sellerTeam',
        minWidth: '10',
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        minWidth: '12',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        minWidth: '8',
        align: 'center',
      },
    ]

    const getAllStoresSellers = async () => {
      const listStore = await getAllStores()
      stores.value = listStore as any[]
    }

    const dialogVisible = ref<boolean>(false)
    const customerDetailDialog =
      ref<InstanceType<typeof CustomerDetailDialog>>()
    const onViewDetailCustomer = async (email: string) => {
      isLoading.value = true
      await customerDetailDialog.value?.toggle(email)
      isLoading.value = false
    }

    const emailDialog = ref<InstanceType<typeof EmailDialog>>()
    const openEmailDialog = async () => {
      emailDialog.value?.open(params)
    }

    const successNoti = () => {
      ElMessage({
        message: 'Successfully!',
        type: 'success',
      })
    }

    const failNoti = () => {
      ElMessage({
        message: 'Failed!Try again...',
        type: 'error',
      })
    }

    return {
      params,
      fetchData,
      countries,
      dialogVisible,
      customerDetailDialog,
      onViewDetailCustomer,
      content,
      stores,
      documents,
      isLoading,
      total,
      columns,
      imageUrl,
      formatTime,
      formatterDollars,
      emailDialog,
      openEmailDialog,
      successNoti,
      failNoti,
    }
  },
})
